<template>
<VNavigationDrawer
expand-on-hover
rail
>
<v-list>
<v-list-item
          prepend-avatar="https://lh3.googleusercontent.com/a/AGNmyxZ2C-tDradK-zZsOpire2JLoJZCzvEUo64YUgJZSw=s192-c-rg-br100"
          title="Madhu Singh"
          subtitle="contact@madhu.nl"
        >
</v-list-item>
</v-list>
<VDivider/>
<v-list density="compact" nav>
          <v-list-item prepend-icon="mdi-briefcase-outline" title="Experience" value="workexperience"></v-list-item>
          <v-list-item prepend-icon="mdi-school-outline" title="Education" value="education"></v-list-item>          
        </v-list>

</VNavigationDrawer>
</template>